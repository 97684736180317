import {NavLink} from "react-router-dom";
import { Routes, Route, Outlet } from "react-router-dom";
import Header from "./components/Header";
import 'normalize.css';
import logo from './images/logo.png';
import React from "react";

/*const jsc8 = require("jsc8");
const client = new jsc8({
    url: "https://squeaker-50556912-eu-central.paas.macrometa.io",
    apiKey: "info_sanderhannema.nl.werkttTestId.yCKdTFdnKrijasvwKi7AvIzlkTKyMOe0lRGXMJ3ZFYcMAwr4DwcAd2VNZwhEHLsw1b5cb1"
});

async function c8Queries(collectionName) {
    const result = await client.executeQuery(
        "FOR element IN " + collectionName + " RETURN element"
    );
    await console.log(result);
}

c8Queries("testCollection");
*/

function PageLayout() {
    document.body.classList.remove('login');

    return (
        <>
            <Header/>
            <div id="content">
                <Outlet/>
            </div>
        </>
    )
}

function LoginLayout() {
    document.body.classList.add('login');

    return (
        <>
            <div id="content">
                <NavLink to="/"><img src={logo} alt="Home" /></NavLink>
                <Outlet/>
            </div>
        </>
    )
}

function Home() {
    return (
        <>
           <div id="home" className="content-inner">
               <div className="info">
                   <h1>Werkt je website nog?</h1>
                   <h2>Wij checken het iedere minuut en sturen je automatisch een sms</h2>
                   <p>Met de tool ben je direct op de hoogte wanneer je website langzaam laadt of als je SSL certificaat bijna verloopt. Cruciale informatie waarmee je actie kan ondernemen om bezoekers te behouden en omzet veilig te stellen.</p>
                   <NavLink to="/features" className="button button-outline">Meer informatie</NavLink> <NavLink to="/sign-up" className="button">Start gratis</NavLink>
               </div>
           </div>
        </>
    );
}

function Prices() {
    return (
        <>
            Prijzen
        </>
    );
}

function Signup() {
    return (
        <div className="content-inner">
            <h1>Gratis account aanmaken</h1>
            <form method="post" action="/sign-up">
                <div className="row">
                    <label htmlFor="email">E-mail</label>
                    <input type="text" name="email" id="email" />
                </div>
                <div className="row">
                    <label htmlFor="password">Wachtwoord</label>
                    <input type="password" name="password" id="password" />
                </div>
                <div className="row">
                    <label htmlFor="domain">Voer de website in die je graag door ons wilt laten checken</label>
                    <input type="text" name="domain" id="domain" />
                </div>
                <div className="row">
                    <input type="button" name="submit" value="Gratis account aanmaken" className="button" />
                </div>
            </form>
        </div>
    );
}

export default function App() {
    return (
        <>werktt.nl</>
    )
}